import { GlobalDispatchContext, GlobalStateContext } from '@context/GlobalContextProvider';
import { useContext } from 'react';

const useGlobalContext = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  if (!state || !dispatch) {
    throw new Error('useGlobalContext must be used within a GlobalContextProvider');
  }

  return { globalState: state, globalDispatch: dispatch };
};

export default useGlobalContext;
