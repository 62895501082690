import { useM360UI } from '@madwire/m360ui';
import { ReactElement, SVGProps } from 'react';

type Props = Partial<SVGProps<SVGElement>>;

const YourUpdatesWebsitesLogo = (props: Props): ReactElement => {
  const { theme } = useM360UI();
  const mainFill = props?.style?.fill || theme.colors.cta.primary;
  return (
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <rect fill="none" width="40" height="40" />
      <path
        fill={theme.colors.white}
        transform="translate(-13.116 -37.333)"
        d="M16.45,42.335v15h4.376l14.033-12.28,4.923,4.923,7.643-7.643H41.448V40.62H38.114v1.715Z"
      />
      <path
        fill={theme.colors.white}
        transform="translate(-50.239 -53.352)"
        d="m86.905 66.687v-5.977l-10 10-5.075-5.075-8.82 7.718h23.9v-3.332h1.848v-3.334z"
      />
      <path
        fill={theme.colors.white}
        transform="translate(-13.116 -116.2)"
        d="M24.782,139.54H16.45v3.332H49.782V139.54Z"
      />
      <path
        fill={theme.colors.white}
        transform="translate(-30.338 -37.333)"
        d="M64.647,42.335,57,49.978l-4.921-4.925L38.05,57.335h1.1c3.816-2.667,7.436-6.076,11.9-8.689,8.512,5.026,12.017-2.355,17.809-8.026H58.672v1.717h5.975Z"
      />
      <path
        fill={mainFill}
        transform="translate(-30.33 -30.97)"
        d="M58.662,32.64v3.332h5.977L57,43.615l-4.923-4.923L38.04,50.972H43.1l8.82-7.718L57,48.329l10-10v5.977h3.332V32.64Z"
      />
      <path
        fill={mainFill}
        transform="translate(0 -30.963)"
        d="M36.666,50.964H3.334v-15H25V32.63H3.334A3.339,3.339,0,0,0,0,35.964V57.63a3.338,3.338,0,0,0,3.334,3.334H15V64.3H13.334v3.334H26.666V64.3H25V60.964H36.666A3.338,3.338,0,0,0,40,57.63v-10H36.666Zm-15,13.334H18.332V60.964h3.334V64.3Zm15-6.668H3.334V54.3h33.33V57.63Z"
      />
    </svg>
  );
};

export default YourUpdatesWebsitesLogo;
