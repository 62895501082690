/* eslint-disable @typescript-eslint/ban-ts-comment */
import ZapierLogo from '@components/crm/common/ZapierLogo';
import YourUpdatesWebsitesLogo from '@components/dashboard/YourUpdatesWebsitesLogo';
import { useBillingAccount } from '@context/BillingAccountContextProvider';
import useEmployee from '@hooks/useEmployee';
import {
  ButtonLink,
  Card,
  CardDivider,
  Flex,
  Heading,
  IconButton,
  Link,
  Tag,
  Text,
  useM360UI,
  useResponsiveValue
} from '@madwire/m360ui';
import {
  AssessmentOutlined,
  AttachMoneyOutlined,
  Check,
  Close,
  DynamicFormOutlined,
  Insights,
  PersonalVideoOutlined,
  PhoneAndroid,
  PhonelinkRingOutlined,
  PlayCircleOutline,
  AutoAwesome
} from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { AccountAssignedEmployee, AccountCorePlan } from '@typedefs/Account';
import { GenericObject } from '@typedefs/General';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, SVGProps, useEffect, useMemo, useState } from 'react';
import { openPopupWidget } from 'react-calendly';

/**
 * Types
 */
type IconType = OverridableComponent<SvgIconTypeMap<GenericObject, 'svg'>> & { muiName?: string };
type ReactElementSVG = (props: Partial<SVGProps<SVGElement>>) => ReactElement;

type IconMap = {
  [key: string]: IconType | ReactElementSVG;
};

/**
 * Constants
 */
const ICON_MAP: IconMap = {
  Insights,
  PlayCircleOutline,
  PhoneAndroid,
  PersonalVideoOutlined,
  AttachMoneyOutlined,
  PhonelinkRingOutlined,
  AssessmentOutlined,
  Check,
  DynamicFormOutlined,
  AutoAwesome,
  ZapierLogo,
  YourUpdatesWebsitesLogo
};
const LOCAL_STORAGE_PREFIX = 'hideUpdate: ';
// this core planId for Full Platform is stable across environments
export const FULL_PLATFORM_ID = 4;

/**
 * Component
 */
const DashboardUpdates = () => {
  const isMobile = useResponsiveValue([true, true, false, false]);
  const { t } = useTranslation('dashboard');
  const router = useRouter();
  const [updateToggle, setUpdateToggle] = useState(false);
  const { accountData } = useBillingAccount();
  const isFullPlatform = accountData?.plans.find(
    (plan: AccountCorePlan) => plan.planId === FULL_PLATFORM_ID
  );
  const { theme } = useM360UI();

  const { employee } = useEmployee();
  const assignedEmployee = employee as AccountAssignedEmployee;

  useEffect(() => {
    const removeAppcueParam = () => {
      const { query } = router;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { appcue, ...rest } = query;
      router.replace({ query: { ...rest } });
    };
    window.Appcues?.on('flow_skipped', removeAppcueParam);
    window.Appcues?.on('flow_completed', removeAppcueParam);
    return () => {
      window.Appcues?.off('flow_skipped', removeAppcueParam);
      window.Appcues?.off('flow_completed', removeAppcueParam);
    };
  }, [router]);

  const updates = useMemo(() => {
    const defaultUpdates = [
      {
        icon: 'AutoAwesome',
        description: t('yourUpdates.aiTextGenerator.description'),
        ctaText: t('yourUpdates.aiTextGenerator.ctaText'),
        ctaLink: '?appcue=e9b39113-47cb-4dd2-8079-3e36e91cb201'
      },
      {
        icon: 'YourUpdatesWebsitesLogo',
        description: t('yourUpdates.manageWebsites.description'),
        ctaText: t('yourUpdates.manageWebsites.ctaText'),
        ctaLink: '?appcue=b7467ed2-af51-49f5-8b72-e43ea65a5920'
      },
      {
        icon: 'ZapierLogo',
        description: t('yourUpdates.zapierLeads.description'),
        ctaText: t('yourUpdates.zapierLeads.ctaText'),
        ctaLink: '?appcue=68eee19a-872b-48c9-8c9f-63be59ae6c57'
      },
      {
        icon: 'DynamicFormOutlined',
        description: t('yourUpdates.customizeForms.description'),
        ctaText: t('yourUpdates.customizeForms.ctaText'),
        ctaLink: '?appcue=3fb3a032-951b-48b5-b015-601f1274c93e'
      },
      ...(accountData?.country === 'US' &&
      accountData?.billingType === 'stripe' &&
      accountData?.agencyId === 1 &&
      isFullPlatform
        ? [
            {
              icon: 'Check',
              description: t('yourUpdates.googleGuaranteed.description'),
              ctaText: t('yourUpdates.googleGuaranteed.ctaText'),
              ctaLink: `?appcue=63d0c8e8-3869-411a-b316-42b576c21bb6`
            }
          ]
        : []),
      // These are commented out per https://app.shortcut.com/madwire/story/65333/your-updates-forms;
      // they're strongly expected to come back at some point. We'll need to restore the useModalManager
      // import when we're ready to re-include the introVideo item below.
      // {
      //   icon: 'PersonalVideoOutlined',
      //   description: t('yourUpdates.takeATour.description'),
      //   ctaText: t('yourUpdates.takeATour.ctaText'),
      //   ctaLink: `?appcue=013fffa6-2268-419c-bdf3-52e854b0f4b6`
      // },
      // {
      //   useButtonLink: true,
      //   icon: 'PlayCircleOutline',
      //   description: t('yourUpdates.watchIntroVideo.description'),
      //   ctaText: t('yourUpdates.watchIntroVideo.ctaText'),
      //   ctaCallback: () => openModal(<DashboardTipsAndTricksModal />)
      // },
      // when restored, this one went below scheduleDemo
      // ...(accountData?.country === 'US'
      //   ? [
      //       {
      //         icon: 'PhoneAndroid',
      //         description: t('yourUpdates.downloadApp.description'),
      //         ctaText: t('yourUpdates.downloadApp.ctaText'),
      //         ctaLink: `/?appcue=79e782b1-e156-4587-b7a5-c54c691cdddf`
      //       }
      //     ]
      //   : []),
      // Only add the request demo update if the user has an employee with a meeting link
      ...(assignedEmployee?.meetingLink
        ? [
            {
              useButtonLink: true,
              icon: 'Insights',
              description: t('yourUpdates.scheduleDemo.description'),
              ctaText: t('yourUpdates.scheduleDemo.ctaText'),
              ctaCallback: () =>
                openPopupWidget({
                  // non-null assertion is OK because of the ternary condition we're in
                  url: assignedEmployee.meetingLink!
                })
            }
          ]
        : []),
      {
        icon: 'AssessmentOutlined',
        description: t('yourUpdates.monitorYourLeads.description'),
        ctaText: t('yourUpdates.monitorYourLeads.ctaText'),
        ctaLink: '/crm/overview'
      },
      ...(accountData?.country === 'US'
        ? [
            {
              icon: 'PhonelinkRingOutlined',
              description: t('yourUpdates.tapToPay.description'),
              ctaText: t('yourUpdates.tapToPay.ctaText'),
              ctaLink: `/?appcue=1b071bf1-95c6-4b65-8104-9e82494a9354`
            }
          ]
        : [])
    ];

    return defaultUpdates.filter(
      update => !localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${update.description}`)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee, updateToggle]);

  return updates.length ? (
    <Card mx={{ mb: 3 }}>
      <Heading as="h2" type="h5" mx={{ display: 'flex', alignItems: 'center' }}>
        {t('yourUpdates.sectionTitle')}
        <Tag
          color="blue"
          mx={{
            ml: 2,
            px: 2
          }}
        >
          {/* Todo: we may need to change this if the updates API is paginated? */}
          {updates.length}
        </Tag>
      </Heading>

      {updates.map(({ icon, description, ctaText, ctaLink, ctaCallback, useButtonLink }, index) => {
        const UpdateIcon = ICON_MAP[icon];
        const ctaLinkComponent =
          useButtonLink && ctaLink ? (
            <NextLink href={ctaLink} passHref>
              {/* @ts-ignore */}
              <ButtonLink
                as="a"
                onClick={ctaCallback}
                mx={{
                  display: 'inline',
                  height: 'auto',
                  fontWeight: 'normal',
                  lineHeight: 'default',
                  marginRight: 3,
                  padding: 0,
                  textAlign: 'left'
                }}
              >
                {ctaText}
              </ButtonLink>
            </NextLink>
          ) : (
            ctaLink && (
              <NextLink href={ctaLink} passHref>
                {/* @ts-ignore */}
                <Link
                  onClick={ctaCallback}
                  mx={{
                    marginRight: 3
                  }}
                >
                  {ctaText}
                </Link>
              </NextLink>
            )
          );
        return (
          <Fragment key={index}>
            <CardDivider />
            {/* @ts-ignore */}
            <Flex mx={{ justifyContent: 'space-between' }}>
              {/* @ts-ignore */}
              <Flex mx={{ alignItems: 'center' }}>
                <Tag
                  mx={{
                    height: 32,
                    width: 32,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '> svg': {
                      color: 'cta.primary'
                    }
                  }}
                >
                  <UpdateIcon style={{ fill: theme?.colors?.cta?.primary }} />
                </Tag>
                {/* @ts-ignore */}
                <Flex mx={{ flexDirection: 'column', marginLeft: 3 }}>
                  {/* @ts-ignore */}
                  <Text mx={{ marginBottom: 0 }}>{description}</Text>
                  {isMobile && ctaLinkComponent}
                </Flex>
              </Flex>
              {/* @ts-ignore */}
              <Flex mx={{ flexDirection: ['column', 'row'], alignItems: 'center' }}>
                {!isMobile && ctaLinkComponent}
                <IconButton
                  label="Close"
                  onClick={() => {
                    localStorage.setItem(
                      `${LOCAL_STORAGE_PREFIX}${description}`,
                      JSON.stringify(true)
                    );
                    setUpdateToggle(!updateToggle);
                  }}
                  mx={{
                    color: 'neutral.100'
                  }}
                >
                  <Close />
                </IconButton>
              </Flex>
            </Flex>
          </Fragment>
        );
      })}
    </Card>
  ) : null;
};

export default DashboardUpdates;
